.vimeo-content {
  position: relative;
  overflow: hidden;
}

.vimeo-content .vimeo__player {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.vimeo-content .vimeo__player iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.vimeo-content .vimeo-content__modal {
  z-index: 6;
  background-color: #162224e6;
  width: 100dvw;
  height: 100dvh;
  padding: 70px 0;
  display: none;
  position: fixed;
  inset: 0;
}

.vimeo-content .vimeo-content__modal > .container {
  -webkit-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
}

.vimeo-content .vimeo-content__modal .modal__video {
  width: 100%;
  height: 100%;
  position: relative;
}

.vimeo-content .vimeo-content__modal .modal__button {
  z-index: 1;
  cursor: pointer;
  top: -30px;
  right: 10px;
  position: absolute !important;
}

.vimeo-content .vimeo-content__modal .modal__button, .vimeo-content .vimeo-content__modal .modal__button:visited {
  cursor: pointer;
  color: #f5f5f5;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  background-color: #0000;
  border: 2px solid #f5f5f5;
  border-radius: 40px;
  align-items: center;
  padding: 15px 20px;
  font-family: Eina Bold, sans-serif;
  font-size: .875em;
  font-style: normal;
  font-weight: 700;
  line-height: 1em;
  transition: background-color .3s, color .3s;
  display: -webkit-inline-flex;
  display: inline-flex;
}

.vimeo-content .vimeo-content__modal .modal__button svg path, .vimeo-content .vimeo-content__modal .modal__button:visited svg path {
  transition: fill .3s;
  fill: #f5f5f5 !important;
}

.vimeo-content .vimeo-content__modal .modal__button:hover, .vimeo-content .vimeo-content__modal .modal__button:active {
  text-decoration: none;
  color: #162224 !important;
  background-color: #f5f5f5 !important;
}

.vimeo-content .vimeo-content__modal .modal__button:hover svg path, .vimeo-content .vimeo-content__modal .modal__button:active svg path {
  fill: #162224 !important;
}
/*# sourceMappingURL=vimeo-content.css.map */
