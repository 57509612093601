@import "../config";
@import "../mixins/mixins-base";
@import "../mixins/mixins-buttons";

.vimeo-content {
    position: relative;
    overflow: hidden;

    .vimeo__player {
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        iframe {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
        }
    }

    .vimeo-content__modal {
        z-index: 6;
        display: none;
        position: fixed;
        width: 100vw;
        width: 100dvw;
        height: 100vh;
        height: 100dvh;
        background-color: rgba($color-black, 0.9);
        padding: 70px 0;
        inset: 0;
        
        > .container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }

        .modal__video {
            position: relative;
            width: 100%;
            height: 100%;
        }
        
        .modal__button {
            @include outline-button('white');
            z-index: 1;
            position: absolute !important;
            top: -30px;
            right: 10px;
            cursor: pointer;
        }
    }
}